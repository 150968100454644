.domaine-entreprise-container{
	width: 90%;
	margin: auto;
}

.domaines {
	font-size: 1.5em;
	margin : 0;
	margin-block-start: 0.83em;
	margin-block-end: 0.83em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}

.logos-grid{
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(125px, 125px));
	grid-gap: 20px;
	align-items: center;
	justify-content: center;
}

.domaine-entreprise-container hr {
	border: 4px solid var(--blue-color);
	border-bottom-width: 0px;
  border-top-width: 8px;
	width: 150px;
	border-radius: 5px;
	margin-left: 0;
	margin-bottom: 30px;
}

.logo-entreprise{
	width: 100%;
	display: flex;
  align-items: center;
}

.nom-entreprise {
	text-align: center;
}
