.NoMatch {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NoMatch-back {
  background: var(--blue-color);
  color: #fff;
  text-decoration: none;
  padding: 1em 2em;
  border-radius: 0.5em;
}

.NoMatch-back:hover {
  background: linear-gradient(135deg, #f7d16cd8 15%,#ec8e13 100%);
  box-shadow: 0 0 11px #d4d4d4;
}