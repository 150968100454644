.sectionTitle {
  text-align: center;
  font-weight: lighter;
  margin: 40px auto;
  padding: 0 20px;
  font-family: "raleway-bold";
  font-size: 2em;
}

.titleEnd {
  color: var(--orange-color);
}

@media only screen and (max-width: 375px) {
  .sectionTitle {
    font-size: 1.6em;
  }
}
