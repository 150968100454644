.article-container {
  display: block;
  text-align: left;
}
.article{
  margin : 50px 0px;
}
.hr-color {
  border: 4px solid var(--blue-color);
  border-bottom-width: 0px;
  border-top-width: 8px;
  width: 150px;
  border-radius: 5px;
  margin: 15px auto 20px 0;
  }
.text-titre {
  font-size: 1.5em; 
  margin: 0;   
}

.text-contenu p {
  font-size: 1em;
  margin: 0 auto 0.6em auto;
}