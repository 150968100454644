.subTitleDiv{
    background-color:var(--blue-color) ;
    padding : 7px 30px;
    width: 80%;
    margin: auto auto 40px auto;
    border-radius : 50px;
    color : white;
}
.subTitleP{
    text-align: center;
}