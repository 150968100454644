.footer-container {
  background-color: var(--black-color);
  color: var(--light-grey-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  line-height: 0.7;
  font-size: 0.9em;
  width: 100%;
}

.footer-nom-societe {
  font-weight: bold;
  color: var(--blue-color);
  font-size: 1.2em;
}

.footer-logo {
  font-size: 90%;
}

.footer-logo img {
  width: 160px;
  margin-bottom: 1em;
  display: block;
}

.footer-link {
  text-decoration: none;
  color: var(--light-grey-color);
}

.footer-link:hover {
  text-decoration: none;
  color: #fff;
}

@media screen and (min-width: 600px) {
  .footer-container {
    flex-direction: row;
    height: 200px;
    justify-content: space-around;
  }

  .footer-logo img {
    width: 230px;
  }

  .footer-contacts {
    order: -1;
  }
}
