.sitemap-flexbox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.sitemap-nom-societe {
    color: var(--blue-color);
    font-weight: bold;
}

.sitemap-station {
    display: flex;
}

.logoTransport {
    width: 50px;
    height : 22px;
    margin: 0 5px 10px 0;
}

iframe {
    width: 600px;
    height: 300px;
    border: 0;
}

@media screen and (max-width: 1024px) {
    iframe {
        width: 400px;
        height: 250px;
    }
}
@media only screen and (max-width: 870px) and (min-width: 650px) {
    iframe {
        width: 300px;
        height: 200px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 649px) {
    iframe {
        width: 100%;
        height: 150px;
    }
    .sitemap-address {
        width: 100%;
        margin-bottom: 25px;
    }
}
