.homePage-prez{
  text-align: center;
  font-size: 1.2em;
  line-height: 1.2em;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.mvo-video {
  display: flex;
  justify-content: center;
  margin: 30px 0 30px;
}


@media screen and (min-width: 600px) {
  .homePage-prez{
    width: 70%;
  }
}