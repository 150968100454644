.Navbar-container {
  display: none;
}

.TitreCourt {
  list-style-type: none;
  text-align: center;
  text-decoration: none;
  color: black;
}


@media screen and (min-width: 600px) {
  .Navbar-container {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
  }

  .TitreCourt {
    text-align: center;
    font-size: 1.1vw;
  }

  .Navbar-container .active {
    color: var(--blue-color);
  }
  

  .TitreCourt:hover {
    color: var(--blue-color);
  }
}
