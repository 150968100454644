@font-face {
  font-family: 'raleway';
  src: url('./Fonts/Raleway-Regular.ttf')
}

@font-face {
  font-family: 'raleway-italic';
  src: url('./Fonts/Raleway-Italic.ttf')
}

@font-face{
  font-family : 'raleway-bold';
  src: url('./Fonts/Raleway-ExtraBold.ttf')
}

.App {
  font-family: 'raleway';
  --black-color: #0f0f0f;
  --light-grey-color: #D9D9D9;
  --dark-grey-color: #7e7e7e;
  --blue-color: #5AB4FC;
  --orange-color: #CC6600;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

a {
  cursor: pointer;
}

.aboutPage-container,
.changeManagementPage-container,
.contactPage-container,
.outsourcedSalesPage-container,
.referencePage-container,
.reformCertificationPage-container,
.resultProcessPage-container,
.formationListPage-container,
.ficheFormation-container,
.BuyingBehavior-container {
  width: 80%;
  margin: 0 auto 40px auto;
  flex: 1;
}

.loader {
  height: 100px;
  width: 100px;
  border: 8px solid var(--dark-grey-color);
  border-right-color: var(--blue-color);
  border-radius: 100%;
  animation: rotate 1s infinite linear;
  margin: 25vh auto auto auto;
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}