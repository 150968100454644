.formationListPage-container-empty {
  padding: 30px;
  text-align: center;
}

@media screen and (min-width: 682px) {
  .formationListPage-SSC-container{
    display: flex;
    justify-content: space-between;
  }
  .formationListPage-SSC {
    width: 30%;
  }
}