
.logo-certif-container {
  display: grid;
  grid-template-columns: repeat(2, 140px);
  grid-auto-rows: 140px;
  justify-content: center;
  grid-gap: 22px;
  text-align: center;
  margin-top: 40px;
  font-family: 'raleway';
  font-weight: bolder;
  font-size: 19px;
  color: white;
}

.logo-certif {
  width: 100%;
  display: flex;
  align-items: center;
}

.logo-certif-container a{
  display: flex;
  align-items: center;
}

.logo-certif:hover {
  transform: scale(1.04);
  transition: all 600ms;
}

@media only screen  and (min-width : 600px) {
  .logo-certif-container{
      grid-template-columns: repeat(3, 140px);
      grid-gap: 40px;
  }
}

@media only screen  and (max-width : 319px) {
  .logo-certif-container {
      display: grid;
      grid-template-columns: repeat(1, 200px);
      grid-auto-rows: 200px;
  }
}