.header-container {
  box-shadow: 0 2px 3px var(--light-grey-color);
  background-color: white;
  display: flex;
  justify-content: center;
}

.header-block {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
}

.header-logo {
  width: 150px;
}

@media screen and (min-width: 600px) {
  .header-block {
    justify-content: flex-start;
  }

  .header-logo {
    width: 14vw;
  }
}
