.memberTeam-container {
  border: 1px solid var(--light-grey-color);
  padding: 10px;
  text-align: center;
}
.member-nom {
  margin: 0;
  font-weight: bold;
  font-size: 1.5em;
}

.member-fonction {
 font-family: 'raleway-italic';
 font-size: 0.8em;
 margin: 0;
}

.member-logo {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.member-logo img {
  width: 25px;
  margin-left: 10px;
  margin-right: 10px
}

.member-info h4 {
  font-size: 1em;
  font-weight: bold;
  margin: 0.2em auto 0.2em auto;
}

.member-info p {
  font-size: 1em;
  margin:0 auto 0.6em auto;
}