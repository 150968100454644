@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);

.fa-facebook,
.fa-youtube,
.fa-linkedin,
.fa-instagram,
.fa-twitter {
  padding: 10px 12px;
  margin: 0 5px;
  transition: 0.5s;
  color: #fff;
  text-decoration: none;
}

.fa-facebook {
  padding: 10px 17px 10px 15px;
}

.fa-facebook:hover {
  background-color: #3d5b99;
}

.fa-youtube:hover {
  background-color: #e64a41;
}

.fa-linkedin:hover {
  background-color: #0073a4;
}

.fa-instagram:hover {
  background-color: #c13584;
}

.fa-twitter:hover {
  background-color: #00acee;
}