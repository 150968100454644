.teamList-titre {
  font-size: 1.5em; 
  margin: 0;   
}

.teamList-container hr {
  border: 4px solid var(--blue-color);
  border-bottom-width: 0px;
  border-top-width: 8px;
  width: 150px;
  border-radius: 5px;
  margin: 15px auto 20px 0;
}

.teamList-grid {
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 90%;
  justify-content: center;
  grid-gap: 40px;
}

@media only screen  and (min-width : 600px) {
  .teamList-grid{
      grid-template-columns: repeat(2, 45%);
      grid-gap: 40px;
  }
}