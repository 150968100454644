.sousCatTitle-container {
  background-color: var(--blue-color);
  width: 100%;
  margin: auto;
  text-align: center;
}
.sousCatTitle-title, .sousSousCatTitle-title {
  color: white;
  padding: 10px;
}

.sousCatTitle-title {
  font-size: 1.5em;
}

.sousSousCatTitle-title {
  font-size: 1.2em;
}

.sousSousCatTitle-container {
  background-color: var(--dark-grey-color);
  width: fit-content;
}
