.WorldMap-img {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 600px) {
  .WorldMap-img {
    width: 45%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
