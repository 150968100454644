.vignette-list-container{
    display: grid;
    grid-template-columns: repeat(2, 150px);
    grid-auto-rows: 150px;
    justify-content: center;
    grid-gap: 22px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
    font-family: 'raleway';
    font-weight: bolder;
    font-size: 16px;
}

.vignette-orange, .vignette-bleu, .vignette-gris, .vignette-noir{
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 5px #d4d4d4;
    cursor: pointer;
    color: white;
    text-decoration: none;
}

.vignette-text{
    margin : 5px
}

.vignette-orange {
    background-color: var(--orange-color);
}

.vignette-bleu {
    background-color: var(--blue-color);
}


.vignette-gris {
    background-color: var(--dark-grey-color);
}

.vignette-noir {
    background-color: var(--black-color);
}

.vignette-orange:hover, .vignette-bleu:hover, .vignette-gris:hover, .vignette-noir:hover{
  
  opacity: 1;
  background: #f7d16cd8;
  background: -moz-linear-gradient(-45deg, #f7d16cd8 15%, #ec8e13 100%);
  background: -webkit-linear-gradient(-45deg, #f7d16cd8 15%,#ec8e13 100%);
  background: linear-gradient(135deg, #f7d16cd8 15%,#ec8e13 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7d16cd8', endColorstr='#ec8e13',GradientType=1 );
  transform: scale(1.03);
  transition: all 600ms;
  box-shadow: 0 0 11px #d4d4d4; 
}


@media only screen  and (min-width : 600px) {
    .vignette-list-container{
        grid-template-columns: repeat(3, 150px);
        grid-gap: 40px;
    }
}

@media only screen  and (max-width : 320px) {
    .vignette-list-container{
        display: grid;
        grid-template-columns: repeat(1, 200px);
        grid-auto-rows: 200px;
    }
}