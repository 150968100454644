.GraphiqueComportement-img {
  width: 100%;
  height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 600px) {
  .GraphiqueComportement-img {
    width: auto;
    height: auto;
  }
}
