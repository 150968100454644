.button {
    background-color: var(--blue-color);
    border: none;
    border-radius: 15px;
    color: white;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    width: 150px;
    margin: 18px 5px;
    cursor: pointer;
  }

.button:hover {
  font-size: 15px;
  opacity: 1;
  background: #f7d16cd8;
  background: -moz-linear-gradient(-45deg, #f7d16cd8 15%, #ec8e13 100%);
  background: -webkit-linear-gradient(-45deg, #f7d16cd8 15%,#ec8e13 100%);
  background: linear-gradient(135deg, #f7d16cd8 15%,#ec8e13 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7d16cd8', endColorstr='#ec8e13',GradientType=1 );
  transform: scale(1.03);
  transition: all 600ms;
  box-shadow: 0 0 11px #d4d4d4; 
}

.button:focus {
  outline: none;
}

@media screen and (max-width: 600px) {
  .button {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .button {
    padding: 5px;
    width: 90px;
    font-size: 10px;
    margin: 25px 5px;
  }
  
  .button:hover {
    font-size: 10px;
  }
}