@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.subTitleDiv{
    background-color:var(--blue-color) ;
    padding : 7px 30px;
    width: 80%;
    margin: auto auto 40px auto;
    border-radius : 50px;
    color : white;
}
.subTitleP{
    text-align: center;
}
.memberTeam-container {
  border: 1px solid var(--light-grey-color);
  padding: 10px;
  text-align: center;
}
.member-nom {
  margin: 0;
  font-weight: bold;
  font-size: 1.5em;
}

.member-fonction {
 font-family: 'raleway-italic';
 font-size: 0.8em;
 margin: 0;
}

.member-logo {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.member-logo img {
  width: 25px;
  margin-left: 10px;
  margin-right: 10px
}

.member-info h4 {
  font-size: 1em;
  font-weight: bold;
  margin: 0.2em auto 0.2em auto;
}

.member-info p {
  font-size: 1em;
  margin:0 auto 0.6em auto;
}
.teamList-titre {
  font-size: 1.5em; 
  margin: 0;   
}

.teamList-container hr {
  border: 4px solid var(--blue-color);
  border-bottom-width: 0px;
  border-top-width: 8px;
  width: 150px;
  border-radius: 5px;
  margin: 15px auto 20px 0;
}

.teamList-grid {
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 90%;
  justify-content: center;
  grid-gap: 40px;
}

@media only screen  and (min-width : 600px) {
  .teamList-grid{
      grid-template-columns: repeat(2, 45%);
      grid-gap: 40px;
  }
}
.sectionTitle {
  text-align: center;
  font-weight: lighter;
  margin: 40px auto;
  padding: 0 20px;
  font-family: "raleway-bold";
  font-size: 2em;
}

.titleEnd {
  color: var(--orange-color);
}

@media only screen and (max-width: 375px) {
  .sectionTitle {
    font-size: 1.6em;
  }
}

.WorldMap-img {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 600px) {
  .WorldMap-img {
    width: 45%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.article-container {
  display: block;
  text-align: left;
}
.article{
  margin : 50px 0px;
}
.hr-color {
  border: 4px solid var(--blue-color);
  border-bottom-width: 0px;
  border-top-width: 8px;
  width: 150px;
  border-radius: 5px;
  margin: 15px auto 20px 0;
  }
.text-titre {
  font-size: 1.5em; 
  margin: 0;   
}

.text-contenu p {
  font-size: 1em;
  margin: 0 auto 0.6em auto;
}
.changeManagementPage-container {
  text-align: center;
}
.changeManagementPage-img {
  width: 80%;
}
.form {
    margin-bottom: 50px;
}
.hr-col {
    border: 4px solid var(--blue-color);
    border-bottom-width: 0px;
    border-top-width: 8px;
    width: 150px;
    border-radius: 5px;
    margin: 15px auto 20px 0;
}
.testform, .textAndButton {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.form-input{
    width: 100%;
    border: 2px solid var(--blue-color);
    height: 25px;
    outline: none;
}
.form-textarea {
    width: 100%;
    border: 2px solid var(--blue-color);
    height: 300px;
    outline: none;
}
.form-input:focus{
    border: 2px solid var(--orange-color);
}
.form-textarea:focus {
    border: 2px solid var(--orange-color);
}
.button-send {
    background-color: var(--blue-color);
    border: none;
    width: 100px;
    height: 25px;
    color: white;
    cursor: pointer;
    outline: 0;
}
.button-send:hover {
    background: linear-gradient(135deg, #f7d16cd8 15%,#ec8e13 100%);
    transform: scale(1.03);
    transition: all 600ms;
    box-shadow: 0 0 11px #d4d4d4;
}
@media screen and (min-width: 600px) {
    .form-container {
        display: flex;
    }
    .part {
        width: 40%;
        margin-right: 45px;
    }
    .textAndButton {
        width: 40%;
    }
}
@media screen and (min-width: 900px) {
    .testform {
        flex-direction: row;
    }
    .testform label {
        width: 150px;
    }
}
.sitemap-flexbox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.sitemap-nom-societe {
    color: var(--blue-color);
    font-weight: bold;
}

.sitemap-station {
    display: flex;
}

.logoTransport {
    width: 50px;
    height : 22px;
    margin: 0 5px 10px 0;
}

iframe {
    width: 600px;
    height: 300px;
    border: 0;
}

@media screen and (max-width: 1024px) {
    iframe {
        width: 400px;
        height: 250px;
    }
}
@media only screen and (max-width: 870px) and (min-width: 650px) {
    iframe {
        width: 300px;
        height: 200px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 649px) {
    iframe {
        width: 100%;
        height: 150px;
    }
    .sitemap-address {
        width: 100%;
        margin-bottom: 25px;
    }
}



.fa-facebook,
.fa-youtube,
.fa-linkedin,
.fa-instagram,
.fa-twitter {
  padding: 10px 12px;
  margin: 0 5px;
  transition: 0.5s;
  color: #fff;
  text-decoration: none;
}

.fa-facebook {
  padding: 10px 17px 10px 15px;
}

.fa-facebook:hover {
  background-color: #3d5b99;
}

.fa-youtube:hover {
  background-color: #e64a41;
}

.fa-linkedin:hover {
  background-color: #0073a4;
}

.fa-instagram:hover {
  background-color: #c13584;
}

.fa-twitter:hover {
  background-color: #00acee;
}
.footer-container {
  background-color: var(--black-color);
  color: var(--light-grey-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  line-height: 0.7;
  font-size: 0.9em;
  width: 100%;
}

.footer-nom-societe {
  font-weight: bold;
  color: var(--blue-color);
  font-size: 1.2em;
}

.footer-logo {
  font-size: 90%;
}

.footer-logo img {
  width: 160px;
  margin-bottom: 1em;
  display: block;
}

.footer-link {
  text-decoration: none;
  color: var(--light-grey-color);
}

.footer-link:hover {
  text-decoration: none;
  color: #fff;
}

@media screen and (min-width: 600px) {
  .footer-container {
    flex-direction: row;
    height: 200px;
    justify-content: space-around;
  }

  .footer-logo img {
    width: 230px;
  }

  .footer-contacts {
    order: -1;
  }
}

.formationTitle-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.formationTitle-line {
  width: 40px;
  margin-right: 1em;
}

.formationTitle-title {
  font-size: 1em;
  text-decoration: none;
  color: var(--black-color);
}

.formationTitle-title:hover {
  color: var(--blue-color);
}
.sousCatTitle-container {
  background-color: var(--blue-color);
  width: 100%;
  margin: auto;
  text-align: center;
}
.sousCatTitle-title, .sousSousCatTitle-title {
  color: white;
  padding: 10px;
}

.sousCatTitle-title {
  font-size: 1.5em;
}

.sousSousCatTitle-title {
  font-size: 1.2em;
}

.sousSousCatTitle-container {
  background-color: var(--dark-grey-color);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.formationListPage-container-empty {
  padding: 30px;
  text-align: center;
}

@media screen and (min-width: 682px) {
  .formationListPage-SSC-container{
    display: flex;
    justify-content: space-between;
  }
  .formationListPage-SSC {
    width: 30%;
  }
}
.vignette-list-container{
    display: grid;
    grid-template-columns: repeat(2, 150px);
    grid-auto-rows: 150px;
    justify-content: center;
    grid-gap: 22px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
    font-family: 'raleway';
    font-weight: bolder;
    font-size: 16px;
}

.vignette-orange, .vignette-bleu, .vignette-gris, .vignette-noir{
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 5px #d4d4d4;
    cursor: pointer;
    color: white;
    text-decoration: none;
}

.vignette-text{
    margin : 5px
}

.vignette-orange {
    background-color: var(--orange-color);
}

.vignette-bleu {
    background-color: var(--blue-color);
}


.vignette-gris {
    background-color: var(--dark-grey-color);
}

.vignette-noir {
    background-color: var(--black-color);
}

.vignette-orange:hover, .vignette-bleu:hover, .vignette-gris:hover, .vignette-noir:hover{
  
  opacity: 1;
  background: #f7d16cd8;
  background: linear-gradient(135deg, #f7d16cd8 15%,#ec8e13 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7d16cd8', endColorstr='#ec8e13',GradientType=1 );
  transform: scale(1.03);
  transition: all 600ms;
  box-shadow: 0 0 11px #d4d4d4; 
}


@media only screen  and (min-width : 600px) {
    .vignette-list-container{
        grid-template-columns: repeat(3, 150px);
        grid-gap: 40px;
    }
}

@media only screen  and (max-width : 320px) {
    .vignette-list-container{
        display: grid;
        grid-template-columns: repeat(1, 200px);
        grid-auto-rows: 200px;
    }
}
.burgerMenu-menu {
  width: 40px;
  cursor: pointer;
}

.burgerMenu-side {
  display: flex;
  flex-direction: column;
  background-color: var(--blue-color);
  color: white;
  width: 300px;
  height: 100%;
  font-size: 1em;
  position: fixed;
  top: 0;
  transition: width 300ms ease-in;
  overflow: hidden;
  white-space: nowrap;
  left: 0;
  box-shadow: 6px 0px 34px 12px rgba(0, 0, 0, 0.64);
  z-index: 1;
}

.burgerMenu-hide {
  width: 0;
  box-shadow: none;
}

.burgerMenu-close {
  width: 30px;
  align-self: flex-end;
  margin: 7px;
  cursor: pointer;
}

.burgerMenu-links {
  height: 70vh;
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.burgerMenu-Link {
  text-decoration: none;
  color: white;
}

@media screen and (min-width: 600px) {
  .burgerMenu-container {
    display: none;
  }
}

.Navbar-container {
  display: none;
}

.TitreCourt {
  list-style-type: none;
  text-align: center;
  text-decoration: none;
  color: black;
}


@media screen and (min-width: 600px) {
  .Navbar-container {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
  }

  .TitreCourt {
    text-align: center;
    font-size: 1.1vw;
  }

  .Navbar-container .active {
    color: var(--blue-color);
  }
  

  .TitreCourt:hover {
    color: var(--blue-color);
  }
}

.button {
    background-color: var(--blue-color);
    border: none;
    border-radius: 15px;
    color: white;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    width: 150px;
    margin: 18px 5px;
    cursor: pointer;
  }

.button:hover {
  font-size: 15px;
  opacity: 1;
  background: #f7d16cd8;
  background: linear-gradient(135deg, #f7d16cd8 15%,#ec8e13 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7d16cd8', endColorstr='#ec8e13',GradientType=1 );
  transform: scale(1.03);
  transition: all 600ms;
  box-shadow: 0 0 11px #d4d4d4; 
}

.button:focus {
  outline: none;
}

@media screen and (max-width: 600px) {
  .button {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .button {
    padding: 5px;
    width: 90px;
    font-size: 10px;
    margin: 25px 5px;
  }
  
  .button:hover {
    font-size: 10px;
  }
}
.header-container {
  box-shadow: 0 2px 3px var(--light-grey-color);
  background-color: white;
  display: flex;
  justify-content: center;
}

.header-block {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
}

.header-logo {
  width: 150px;
}

@media screen and (min-width: 600px) {
  .header-block {
    justify-content: flex-start;
  }

  .header-logo {
    width: 14vw;
  }
}


.logo-certif-container {
  display: grid;
  grid-template-columns: repeat(2, 140px);
  grid-auto-rows: 140px;
  justify-content: center;
  grid-gap: 22px;
  text-align: center;
  margin-top: 40px;
  font-family: 'raleway';
  font-weight: bolder;
  font-size: 19px;
  color: white;
}

.logo-certif {
  width: 100%;
  display: flex;
  align-items: center;
}

.logo-certif-container a{
  display: flex;
  align-items: center;
}

.logo-certif:hover {
  transform: scale(1.04);
  transition: all 600ms;
}

@media only screen  and (min-width : 600px) {
  .logo-certif-container{
      grid-template-columns: repeat(3, 140px);
      grid-gap: 40px;
  }
}

@media only screen  and (max-width : 319px) {
  .logo-certif-container {
      display: grid;
      grid-template-columns: repeat(1, 200px);
      grid-auto-rows: 200px;
  }
}
.homePage-prez{
  text-align: center;
  font-size: 1.2em;
  line-height: 1.2em;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.mvo-video {
  display: flex;
  justify-content: center;
  margin: 30px 0 30px;
}


@media screen and (min-width: 600px) {
  .homePage-prez{
    width: 70%;
  }
}

.domaine-entreprise-container{
	width: 90%;
	margin: auto;
}

.domaines {
	font-size: 1.5em;
	margin : 0;
	-webkit-margin-before: 0.83em;
	        margin-block-start: 0.83em;
	-webkit-margin-after: 0.83em;
	        margin-block-end: 0.83em;
	-webkit-margin-start: 0px;
	        margin-inline-start: 0px;
	-webkit-margin-end: 0px;
	        margin-inline-end: 0px;
}

.logos-grid{
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(125px, 125px));
	grid-gap: 20px;
	align-items: center;
	justify-content: center;
}

.domaine-entreprise-container hr {
	border: 4px solid var(--blue-color);
	border-bottom-width: 0px;
  border-top-width: 8px;
	width: 150px;
	border-radius: 5px;
	margin-left: 0;
	margin-bottom: 30px;
}

.logo-entreprise{
	width: 100%;
	display: flex;
  align-items: center;
}

.nom-entreprise {
	text-align: center;
}




.GraphiqueComportement-img {
  width: 100%;
  height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 600px) {
  .GraphiqueComportement-img {
    width: auto;
    height: auto;
  }
}

.NoMatch {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NoMatch-back {
  background: var(--blue-color);
  color: #fff;
  text-decoration: none;
  padding: 1em 2em;
  border-radius: 0.5em;
}

.NoMatch-back:hover {
  background: linear-gradient(135deg, #f7d16cd8 15%,#ec8e13 100%);
  box-shadow: 0 0 11px #d4d4d4;
}
@font-face {
  font-family: 'raleway';
  src: url(/static/media/Raleway-Regular.9942588a.ttf)
}

@font-face {
  font-family: 'raleway-italic';
  src: url(/static/media/Raleway-Italic.b4329739.ttf)
}

@font-face{
  font-family : 'raleway-bold';
  src: url(/static/media/Raleway-ExtraBold.be3bf63a.ttf)
}

.App {
  font-family: 'raleway';
  --black-color: #0f0f0f;
  --light-grey-color: #D9D9D9;
  --dark-grey-color: #7e7e7e;
  --blue-color: #5AB4FC;
  --orange-color: #CC6600;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

a {
  cursor: pointer;
}

.aboutPage-container,
.changeManagementPage-container,
.contactPage-container,
.outsourcedSalesPage-container,
.referencePage-container,
.reformCertificationPage-container,
.resultProcessPage-container,
.formationListPage-container,
.ficheFormation-container,
.BuyingBehavior-container {
  width: 80%;
  margin: 0 auto 40px auto;
  flex: 1 1;
}

.loader {
  height: 100px;
  width: 100px;
  border: 8px solid var(--dark-grey-color);
  border-right-color: var(--blue-color);
  border-radius: 100%;
  -webkit-animation: rotate 1s infinite linear;
          animation: rotate 1s infinite linear;
  margin: 25vh auto auto auto;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
