.formationTitle-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.formationTitle-line {
  width: 40px;
  margin-right: 1em;
}

.formationTitle-title {
  font-size: 1em;
  text-decoration: none;
  color: var(--black-color);
}

.formationTitle-title:hover {
  color: var(--blue-color);
}