.form {
    margin-bottom: 50px;
}
.hr-col {
    border: 4px solid var(--blue-color);
    border-bottom-width: 0px;
    border-top-width: 8px;
    width: 150px;
    border-radius: 5px;
    margin: 15px auto 20px 0;
}
.testform, .textAndButton {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.form-input{
    width: 100%;
    border: 2px solid var(--blue-color);
    height: 25px;
    outline: none;
}
.form-textarea {
    width: 100%;
    border: 2px solid var(--blue-color);
    height: 300px;
    outline: none;
}
.form-input:focus{
    border: 2px solid var(--orange-color);
}
.form-textarea:focus {
    border: 2px solid var(--orange-color);
}
.button-send {
    background-color: var(--blue-color);
    border: none;
    width: 100px;
    height: 25px;
    color: white;
    cursor: pointer;
    outline: 0;
}
.button-send:hover {
    background: linear-gradient(135deg, #f7d16cd8 15%,#ec8e13 100%);
    transform: scale(1.03);
    transition: all 600ms;
    box-shadow: 0 0 11px #d4d4d4;
}
@media screen and (min-width: 600px) {
    .form-container {
        display: flex;
    }
    .part {
        width: 40%;
        margin-right: 45px;
    }
    .textAndButton {
        width: 40%;
    }
}
@media screen and (min-width: 900px) {
    .testform {
        flex-direction: row;
    }
    .testform label {
        width: 150px;
    }
}